import { IProduct, IRelay, RelayStatus } from "../types";
export const dummyProducts: IProduct[] = [
  {
    id: "0x1",
    title: "Dubai Camel Sticker",
    price: "1.22",
    url: "/assets/camel.png",
    stockQty: 10,
  },
  {
    id: "0x2",
    title: "Money Printer Sticker",
    price: "4.22",
    url: "/assets/printer.png",
    stockQty: 1,
  },
  {
    id: "0x3",
    title: "WAGMI Sticker",
    price: "5.22",
    url: "/assets/wagmi-sticker.png",
    stockQty: 10,
  },
  {
    id: "0x4",
    title: "LFG Sticker",
    price: "5.22",
    url: "/assets/LFG-sticker.png",
    stockQty: 10,
  },
  {
    id: "0x5",
    title: "Rekt Sticker",
    price: "5.22",
    url: "/assets/rekt-sticker.png",
    stockQty: 10,
  },
];

export const dummyRelays: IRelay[] = [
  {
    id: "0x3",
    name: "Pufferfish",
    location: "Unknown",
    status: RelayStatus.Available,
    provisioned: false,
  },
  {
    id: "0x1",
    name: "Weird Fishes",
    location: "Unknown",
    status: RelayStatus.Available,
    provisioned: false,
  },
  {
    id: "0x4",
    name: "CatFish",
    location: "San Francisco, California, United States",
    status: RelayStatus.Unavailable,
    provisioned: false,
  },
  {
    id: "0x8",
    name: "DogFish",
    location: "San Francisco, California, United States",
    status: RelayStatus.Available,
    provisioned: true,
  },
  {
    id: "0x9",
    name: "Betafish",
    location: "Unknown",
    status: RelayStatus.Available,
    provisioned: true,
  },
];
